.page{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.settings-contacts{
    max-width: 900px;
    display: grid; 
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 0 25px;

    textarea{
        max-width: 100%!important;
    }
}
.wrap-steps-config{
    max-width: 370px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 30px;
}
@media (max-width: 992px) {
    .ant-select.ant-select-in-form-item {
        width: 100% !important;
    }

    .settings-contacts{
        grid-template-columns: repeat(auto-fill, 100%);
    }
    
    textarea{
        max-width: 100%!important;
    }
}
