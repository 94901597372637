.buy-credit-page {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-buttons {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

  .payment-buttons-body {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.buy-credit-info {
  margin-top: 20px;
  width: 300px;
  text-align: center;
}

@media (max-width: 767px) {
  .payment-buttons {
    .payment-buttons-body {
      width: 100%;

      button {
        padding: 28px;
      }
    }
  }
}

@media (max-width: 375px) {
  .buy-credit-info {
    width: 300px;
  }
}

@media (max-width: 320px) {
  .payment-buttons {
    .payment-buttons-body {
      margin-top: -40px;

      transform: scale(0.6);
    }
  }
}
