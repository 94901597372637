.infinity-progress {
  width: 80px;
}
.infinity-progress-container {
  width: 100%;
  height: 7px;
  background-color: #e3e3e3;
  border-radius: 10px;
}

.infinity-progress-body {
  width: 50%;
  height: 100%;
  margin-left: 0;
  background-color: #132d36;
  border-radius: 16px;

  animation-name: spinner;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-play-state: running;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  0% {
    margin-left: 0px;
    margin-right: 100%;
  }
  100% {
    margin-left: 50%;
    margin-right: 0;
  }
}
