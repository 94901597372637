//General colors
.wrap-colors {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
}

.display-width {
    width: 768px;
    height: 432px;
}

.mtop {
    margin-top: 28px;
}

.wrap-background {
    display: flex;
    gap: 0 40px;
}

.tv-logo {
    height: auto;
    width: auto;
    max-width: 100%;
    object-fit: cover;
}

.wrap-logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px 0;
    min-height: 197px;

    p {
        line-height: 0;
    }
}

.lable {
    color: #00000061;
    text-shadow: 0 0 0.25px currentcolor;
}

.horizontal {
    display: flex;
    align-items: baseline;
    gap: 0 40px;
}

.backgrounds-list {
    display: grid;
    grid-template-columns: 50px repeat(auto-fill, 50px) 50px 50px;
    width: 100%;
    gap: 10px;
    padding-bottom: 20px;

    .bg-img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        transition: 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: #fff;
        }

        &:hover {
            opacity: 0.8;
        }
    }
}

.mtop-20 {
    margin-top: 20px;
}

.collection {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #7a7a7a;

    svg {
        width: 20px;
        height: 20px;
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.5);
        }
    }
}

.collections-list {
    background-color: #c3bdbd24;
    padding: 10px;
    width: 270px;
    margin-right: 20px;

    &>div {
        margin-bottom: 10px;
    }

    .ant-radio-group {

        label {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            line-height: 1.5715;
            list-style: none;
            font-feature-settings: 'tnum';
            position: relative;
            display: inline-flex;
            align-items: flex-start;
            margin-right: 8px;
            cursor: pointer;
            border-bottom: 1px solid #8080803b;
            margin-bottom: 15px;
            padding-bottom: 10px;
            border-radius: 15px;
        }
    }

}


.btn-back {
    background-color: #f5f5f5;
    color: #282828;
    border: 1px solid #00000047;
    border-radius: 5px;
    height: 32px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        opacity: 0.8;
        transform: scale(1.02);
    }
}

@media (max-width: 1190px) {

    .display-width {
        width: 100%;
        height: 423px;
    }

    .wrap-background {
        flex-direction: column;
    }

    .collections-list {
        background-color: transparent;
        width: 100%;

        .radio-group {
            display: flex;
            flex-wrap: wrap;
        }

    }
}