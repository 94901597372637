.page-settings {
    width: 100%;
    display: flex;
    gap: 10px;
}

.settings-bg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: auto;
}



@media (max-width: 1190px) {
    .page-settings {
        flex-direction: column;
    }
}