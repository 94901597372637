@import "./styles/mixin.scss";
@import "./styles/colors.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

a {
  transition: 0.2s ease-in-out 0s;

  &:hover {
    transform: scale(1.02) !important;
  }
}

.long-text-center {
  display: flex;
  justify-content: center;
}

.card-info-icon {
  svg {
    color: "#cf1222" !important;
  }
}

.head-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.page-blured {
  filter: blur(10px);
  background-color: rgb(190 190 190 / 20%);
}

.site-layout-background {
  padding: 24px;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  overflow: visible;
}

.outlet-content {
  width: 100%;
  min-height: calc(100vh - 160px);
  padding: 30px;
}

.welcome-page {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  @include flexible("", center, space-between);

  .container {
    height: 92vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .welcome-logo {
      width: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h1 {
      color: white;
    }
  }

  img {
    margin-bottom: 20px;
  }
}

.version {
  height: 8vh;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-button {
  width: max-content;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #0c5566;

  a {
    color: white;
  }
}



.pay-button {
  background-color: goldenrod;
  color: white;
  border-radius: 5px !important;

  &:hover {
    color: white;
    background-color: goldenrod;
    border-color: transparent;
  }

  &:focus {
    color: white;
    background-color: goldenrod;
    border-color: transparent;
  }
}

.config-button {
  background-color: transparent;
  color: $app;
  border-radius: 5px !important;
  border-color: transparent !important;

  svg {
    fill: $app;
  }

  &:hover {
    background-color: white;
    color: $app_light;
    border-color: transparent !important;
  }

  &:focus {
    color: $app;
    background-color: white;
    border-color: transparent !important;
  }
}

.ant-tag {
  white-space: break-spaces !important;
}



.ant-table-thead th.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.85) !important;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.85) !important;
}



.ant-table-filter-trigger:hover {
  color: white;
}

.ant-layout-sider .ant-layout-sider-dark .sidebar {
  overflow: visible !important;
}

.ant-layout {
  background-color: white !important;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 2px !important;
}

.ant-select-dropdown {
  position: fixed !important;
}

.icon-country {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    width: 150px;
    gap: 8px;
  }
}

.df {
  display: flex;
  gap: 20px;
}

.test-url {
  border: 1px solid transparent;
  background: #6ab557;
  border-radius: 5px;
  color: #fff;
  width: 120px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;

  &:hover {
    background: #5a973c;
    color: #fff;
  }
}

.ant-segmented-item-selected {
  color: #096dd9;
}

/// upload file
.ant-upload-list {
  color: #4caf50 !important;
}

.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  color: #4caf50 !important;
}

.anticon-delete {
  &:hover {
    color: rgb(217, 105, 105) !important;
  }
}

.ant-upload-list-item-undefined {
  color: rgb(217, 105, 105) !important;

  svg {
    color: rgb(217, 105, 105) !important;
  }
}

.load-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;

  .ant-spin-dot {
    font-size: 45px;
  }
}

.paypal-btn {
  transition: 0.2s ease-in-out 0s;

  &:hover {
    transform: scale(1.03);
  }
}

.long-text {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.coinbase {
  width: 100%;
  background: rgba(25, 82, 151, 1);
  border-radius: 7px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-self: center;

  svg {
    fill: #f7931a;
    width: 20px;
  }

  &:hover {
    color: #fff;
    background: rgba(25, 82, 151, 1);
    opacity: 0.8;
    transform: scale(1.03);
  }

  &:active {
    background: rgba(25, 82, 151, 1);

    color: #fff;
    opacity: 0.8;
  }

  &:focus {
    background: rgba(25, 82, 151, 1);
    color: #fff;
    opacity: 0.8;
  }

  &[disabled] {
    color: darkgray;
    background: #d9d9d9 !important;
    transform: scale(1);

    svg {
      fill: darkgray;
    }
  }
}

.pix {
  width: 100%;
  border-radius: 7px;
  border-color: transparent !important;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: all 0.2s ease;
  background: #1a3435;
  display: flex;
  justify-content: center;

  svg {
    fill: #fff;
    width: 20px;
  }

  &:hover {
    color: #fff;
    opacity: 0.8;
    transform: scale(1.03);
    background: #1a3435;
  }

  &:active {
    color: #fff;
    opacity: 0.8;
    background: #1a3435;
  }

  &:focus {
    color: #fff;
    opacity: 0.8;
    background: #1a3435;
  }

  &[disabled] {
    color: darkgray;
    background: #d9d9d9 !important;
    transform: scale(1);

    svg {
      fill: darkgray;
    }
  }
}

.ameria {
  background: #111214;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  transition: 0.2s;
  width: 100% !important;
  border-radius: 7px !important;

  p {
    margin: 0;
  }

  &:hover {
    color: #fff;
    background: #111214;
    border-color: #000;
    opacity: 0.8;
    transform: scale(1.03);
  }

  &:active {
    color: #fff;
    background: #111214;
    border-color: #000;
    opacity: 0.8;
  }

  &:focus {
    color: #fff;
    background: #111214;
    border-color: #000;
    opacity: 0.8;
  }

  &[disabled] {
    color: darkgray;
    background: #d9d9d9 !important;
    transform: scale(1);
    border-color: darkgray !important;

    svg {
      fill: darkgray;
    }
  }
}

.tb-ameria-card {
  font-size: 12px;
  font-weight: 600;

  img {
    width: 47px;
    object-fit: cover;
  }
}

.regenerate-payed {
  transition: all 0.2s ease;

  svg {
    fill: #1890ff;
  }

  &:hover {
    transform: scale(1.04);
  }

  &[disabled] {
    transform: scale(1);
    color: darkgray !important;
    background: #d9d9d9 !important;

    svg {
      fill: darkgray !important;
    }
  }
}

.ant-btn[disabled]>svg {
  fill: darkgray !important;
  pointer-events: none;
}

.btns-segmented {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid transparent;
  width: 150px;
  padding: 2px;

  button {
    border: 1px solid transparent;
    height: 30px;
  }
}

.bitcoin-payment {
  width: 100%;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 3px;
    height: 45px;
    justify-content: center;
    text-align: center;
    font-size: 17px;
    gap: 10px;
    font-weight: 500;
    transition: 0.2s ease-in-out 0s;

    svg {
      transition: 0.2s ease-in-out 0s;
    }

    &:hover {
      svg {
        fill: #f8c437;
      }

      color: #f8c437;
      border-color: #f8c437;
      cursor: pointer;
      transform: scale(1.02);
    }
  }
}

.save-pix {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 13px;
  height: 47px;
  width: max-content;
}

.invoice-btn-pay {
  border-radius: 4px;
  background: #1890ff;
  border: transparent;

  &:hover {
    background: #1890ff;
    border: transparent;
  }
}

.cvv {
  .ant-row {
    flex-flow: row !important;
    gap: 10px !important;
    margin-top: 28px;

    .ant-form-item-control {
      width: auto !important;
    }
  }
}

.pix-payment {
  margin: 8px auto;
  width: 100%;

  cursor: pointer;
  width: 100%;
  border-radius: 3px;
  text-align: center;
  transition: 0.2s ease-in-out 0s;
  color: #fff;
  border-color: #2a3d3a !important;
  background-color: #2a3d3a !important;
  padding: 0px !important;

  .pix-button-body {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 10px;
  }

  &:hover {
    color: #fff;
    cursor: pointer;
    transform: scale(1.02);
  }

  &:focus {
    color: #fff !important;
  }
}

.active-svg {
  svg {
    fill: #1890ff;
  }
}

.ant-spin-nested-loading>div>.ant-spin.ant-spin-show-text .ant-spin-dot {
  display: none !important;
}

.info {
  cursor: pointer;
  padding-left: 7px;
}

.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: initial !important;
}

.ant-descriptions-item-container {
  display: inherit !important;
}

.table-row-red {
  background: #f5bfbf;
}

.active-row {
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.002);
  }
}

.sub-reseller-info {
  ul {
    list-style: none;
  }
}

// custom popover
.popover-container {
  position: relative;
}

.popover-content {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 0px 10px;
  border: 1px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  bottom: 100%;
}

.popover-trigger {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease;
}

.popover-trigger:hover+.popover-content {
  display: block;
}

.info-modal-info {
  .ant-modal-confirm-btns {
    display: none !important;
  }
}

.info-provider-approved {
  .ant-modal-confirm-btns {
    display: flex;
    align-items: center !important;
    justify-content: center !important;

    button {
      width: 100px;
    }
  }
}

.paid-ant-tag {
  white-space: inherit !important;
}

.search {
  width: 250px;
  margin: 30px 0px;
}

.page-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #000;
  opacity: 0.9;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow: hidden;
}

#copyButton {
  cursor: pointer;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
}

.guide-sector-title {
  text-transform: capitalize;
}

.disabled-txt {
  color: lightgrey;

  svg {
    fill: lightgrey;
  }
}

.infinity {
  line-height: 0px;
}

.login-join-community {
  button {
    cursor: pointer;
    border: 1px solid transparent;
    background-color: transparent;
    color: #1890ff;
    text-decoration: underline;
  }
}

.add-device-info {
  width: 300px;
}

.info-popover {
  border: 1px solid #258ffb !important;
  border-radius: 4px;
  padding: 0px !important;

  .ant-popover-inner {
    background-color: #f8fafc !important;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.info-guide {
  left: calc(100% - 650px) !important;
}

@media (max-width: 992px) {
  .outlet-content {
    padding: 30px 0px !important;
  }
}

@media (max-width: 767px) {
  .site-layout-background {
    padding: 15px;
    margin-top: 30px;
  }

  .info-popover {
    height: 470px;
    overflow: hidden auto;
  }

  .img-upload {
    width: 100% !important;
  }
}

@media (max-width: 490px) {
  .info-popover {
    .ant-popover-inner {
      width: 300px !important;

      .ant-popover-inner-content>div {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
      }
    }
  }

  .info-guide {
    left: 50px !important;
    top: 50px !important;
  }
}

@media (max-width: 430px) {
  .ant-drawer-content-wrapper {
    max-width: 100% !important;
  }

  .ameria {
    gap: 4px;
    padding: 28px 8px;
    font-weight: 700;
    letter-spacing: 0.02em;

    img {
      transform: scale(0.8);
    }
  }

  .coinbase {
    gap: 4px;
    padding: 28px 8px;
    font-weight: 700;
    letter-spacing: 0.02em;
  }

  .pix {
    gap: 4px;
    padding: 28px 8px;
    font-weight: 700;
    letter-spacing: 0.02em;
  }
}

@media (max-width: 320px) {
  .info-guide {
    left: 0px !important;
  }
}

.img-upload {
  position: relative;
  transition: opacity 0.1s ease-in-out;

  .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: auto !important;
  }

  &:hover {
    .img-upload-hover-mask {
      opacity: 1 !important;
    }
  }
}

.img-upload-hover-mask {
  opacity: 0;
  background: rgb(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  transition: opacity 0.1s ease-in-out;
}

.allow-login-info-btn .ant-modal-confirm-btns {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: #43abee !important;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: 0.3s;
    font-weight: 500;
    height: 34px;
    border-radius: 3px;
    transition: all 0.5s ease;

    svg {
      width: 42px;
      height: inherit;
    }

    &:hover {
      transform: scale(1.02);
    }
  }
}

.allow-login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  span {
    display: block;
    overflow: hidden;
    color: #282828;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 1.5;
  }
}

.btn-dashed {
  background: #dc646a !important;
  border: 1px solid #d6535a;
  color: #fff;
  border-radius: 4px;

  &:hover,
  &:focus {
    opacity: 0.7;
    background: #dc646a !important;
    border: 1px solid #d6535a;
    color: #fff;
  }
}

.sketch-picker {
  position: relative !important;
}

.no-color {
  width: 16px;
  height: 16px;
  border-radius: 1px;
  position: absolute;
  right: 6%;
  bottom: 3%;
  cursor: pointer;
  color: #d6535a;
  display: flex;
  justify-content: center;
}

.icon-label {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    object-fit: cover;
    width: 23px;
    height: auto;
    max-width: 100%;
  }
}

.new {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 40px;
    height: auto;
  }
}