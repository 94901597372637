//player
.page-player {
    display: flex;
}

.player {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    position: relative;

    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        z-index: 5;
        position: relative;
    }

    svg {
        width: 22px;
        height: 20px;
    }

}

.df-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
}

.df-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.panel-title {
    font-weight: 600;
    letter-spacing: -0.01em
}

//player-first
.mask {
    background: rgb(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
}

.header-first-player {
    width: 100%;
    height: 100%;
    background: url(../../../images/black-shadow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .header-wrap {
        padding: 15px 20px;
    }
}

.progress-wrap-fp {
    height: 3px;
    width: 100%;
    margin: 4px 0px;
    position: relative;

    .progress {
        width: 100px;
        height: 3px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 0;

        .point {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;


            .in {
                width: 7px;
                height: 7px;
                border-radius: 50%;
            }
        }
    }

}

.player-footer-fp {
    width: 100%;
    height: 100%;
    background: url(../../../images/black-shadow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: rotate(-180deg);

    .footer-wrap {
        transform: rotate(180deg);
        padding: 0px 20px;
        height: 70px;
    }
}

.footer-actions-fp {
    height: 35px;
    align-items: center;
}

//player-second
.header-second-player {
    width: 100%;
    height: 100%;
    background: url(../../../images/black-shadow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .header-wrap {
        padding: 15px 20px;
    }
}

.player-footer-sp {
    width: 100%;
    height: 100%;
    background: url(../../../images/black-shadow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: rotate(-180deg);

    .footer-wrap {
        transform: rotate(180deg);
        padding: 0px 20px;
        height: 70px;
    }
}

.progress-wrap-sp {
    height: 3px;
    width: 100%;
    position: relative;

    .progress {
        width: 190px;
        height: 3px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        top: 0;

        .point {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}

.footer-actions-sp {
    height: 35px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

    div {
        width: 27px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:nth-child(3) {
            transform: scale(1.5);
            width: 25px;
            margin: 0 5px 0 3px;
        }
    }
}


// player-three
.tree-ply-header {
    width: 100%;
    height: 100%;
    background: url(../../../images/black-shadow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .header-wrap {
        padding: 15px 20px;
    }
}

.tree-ply-footer {
    width: 100%;
    height: 100%;
    background: url(../../../images/black-shadow.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: rotate(-180deg);
    display: flex;
    justify-content: center;
    padding: 0px 20px;
    position: relative;

    .footer-wrap {
        transform: rotate(180deg);
        padding: 15px 20px;
        margin-top: 20px;
        height: 70px;
        width: 100%;
        background: rgb(45 45 45 / 70%);
        backdrop-filter: blur(10px);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }

    .play,
    .settings {
        display: flex;
        gap: 7px;
        height: 18px;
    }

    .progress-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 7px;



        .tree-ply-progress {
            height: 3px;
            width: 100%;
            margin: 4px 0px;
            position: relative;
            border-radius: 4px;

            .progress {
                width: 100px;
                height: 3px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: absolute;
                top: 0;
                border-radius: 4px;

                .point {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .in {
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                    }
                }
            }

        }
    }
}

@media (max-width: 1190px) {
    .page-player {
        width: 100%;
        flex-direction: column;
    }

    .player {
        width: -webkit-fill-available;
    }
}