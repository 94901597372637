.bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 6px solid black;
    border-radius: 2px;
    background-origin: border-box;
    background-clip: border-box;
    padding: 0;

    .tv-logo-container {
        height: 90px;

        .tv-logo {
            height: auto;
            width: auto;
            max-width: 100%;
            object-fit: cover;
        }
    }

    .tv-icons-container {
        min-height: 170px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tv-icon-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            width: 120px;
            height: 120px;
            font-weight: bold;
            padding-top: 15px;
            border-radius: 5px;
            margin: 0 15px;

            .tv-icon-custom {
                img {
                    width: 69px;
                    height: 69px;
                    border-radius: 50%;
                }
            }

            svg {
                width: 57px;
                height: 57px;
                padding: 4px;
            }

            .tv-icon {
                width: 43px;
            }

            span {
                padding-bottom: 12px;
            }
        }
    }
}

.classic-wrap-reload {
    width: 170px;
}

.tv-footer-container {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 93%;
    display: flex;
    font-size: 12px;
}