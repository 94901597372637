.ant-select-dropdown {
  width: max-content !important;
}

.label-lang {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000 !important;

  img {
    width: 25px !important;
  }
}

.language-select {
  img {
    width: 27px !important;
  }
}

.language-select .ant-select-focused {
  box-shadow: 0 0 0 2px #10313b;
}

.language-select .ant-select-selection-item {
  display: flex;
  align-items: center;
}


.language-select .ant-select-selection-item .ant-select-selection-placeholder {
  display: none;
}

.language-select .ant-select-selection-item .ant-select-selection-label {
  margin-left: 5px;
}

.language-select {
  .ant-select-selector {
    background-color: transparent !important;
    border: 1px solid transparent !important;
  }
}