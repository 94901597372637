@media (max-width: 767px) {
  .packages-list {
    gap: 0px;
    justify-content: flex-start;
  }

  .package-container {
    width: 100%;

    &.selected-package {
      transform: scale(1);
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #80808080;
    padding: 1px;

    .selected-circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #69c930;
    }
  }

  .package {
    height: auto;
    padding: 10px;
    border: 0 !important;
    border-bottom: 1px solid rgba(217, 217, 217, 1) !important;
    display: grid;
    grid-template-columns: 1fr 7fr 3fr;
    align-items: center;

    .package-body {
      margin-top: 0px;
      justify-content: center;
    }

    .package-title-block-wrap {
      display: flex;
      align-items: center;
      gap: 7px;
      width: max-content;
    }
  }

  .package_credit_body {
    width: 100%;
    justify-content: flex-end;
    margin: 0px !important;
  }

  .trending-mobile {
    width: 100px;
    display: flex !important;
  }

  .package-title-block {
    display: flex;
    align-items: center;
    gap: 10px;

    .package-title {
      font-size: 16px;
    }
  }

  .package-credit {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px !important;
  }

  .price_dollar_web {
    display: none !important;
  }

  .price_dollar_mobile {
    width: 100%;
    text-align: end;
    color: #838383 !important;
    font-size: 17px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;

    .price_dollar {
      color: #3cc82c;
      padding: 2px 6px;
      border-radius: 6px;
      width: max-content;
    }
  }

  .background {
    display: none;
  }

  .package_credit_body {
    font-size: 12px;
  }

  .package-icon {
    svg {
      width: 20px !important;
      height: 20px !important;
      margin-top: 4px;
    }
  }

  .price_br {
    font-size: 15px !important;
  }
}

@media (max-width: 320px) {
  .packages-list {
    margin-top: -20px;
  }
}