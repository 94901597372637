:root {
  --primary-color: #173e4b;
  --btn-color: #173e4b;
  --secondary-color: #132d36;
  --active-color: #132d36;
  --profile-Btn: #74878d;
  --menu-item-color: #979797;
  --menu-active-item-color: #25f662;
  --tb-color: #b8b8b8;
  --card-color: #141638;
  --logo_size: 4;
}

/* menu items */
.ant-menu-item
  > .ant-menu-title-content
  > .df-mneu-items
  > a
  > .custom-menu-item,
.ant-menu-submenu
  > .ant-menu-submenu-title
  > .ant-menu-title-content
  > .df-mneu-items
  > a
  > .custom-menu-item {
  color: var(--menu-item-color) !important;
}
.ant-menu-item > .sidebar-icon,
.ant-menu-submenu > .ant-menu-submenu-title > .sidebar-icon {
  color: var(--menu-item-color) !important;
  width: 28px;
  height: 28px;
}
.ant-menu-submenu-arrow::after,
.ant-menu-submenu-arrow::before {
  background: var(--menu-item-color) !important;
  background-color: var(--menu-item-color) !important;
}

/* selected active menu item */
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item-selected
  > .ant-menu-title-content
  > .df-mneu-items
  > a
  > .custom-menu-item {
  color: var(--menu-active-item-color) !important;
}

.ant-menu-item-selected > .sidebar-icon,
.custom-menu-item {
  background-color: transparent !important;
  color: var(--menu-active-item-color) !important;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title > .sidebar-icon {
  color: var(--menu-active-item-color) !important;
}
.ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-title-content
  > .df-mneu-items
  > a
  > .custom-menu-item {
  color: var(--menu-active-item-color) !important;
}

.ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: var(--menu-active-item-color) !important;
  background-color: var(--menu-active-item-color) !important;
}

/*  */
.ant-btn-primary {
  color: white;
  border-color: transparent !important;
  background: var(--btn-color) !important;
  &:hover {
    color: white;
    background: inherit;
    opacity: 0.8;
    border-color: transparent !important;
  }

  &:focus {
    color: white;
    background: inherit;
    border-color: transparent !important;
  }
}

.plus-button {
  color: white;
  background: var(--btn-color) !important;

  border-radius: 5px !important;

  &:hover {
    color: white;
    background: inherit;
    opacity: 0.8;
    border-color: transparent;
  }

  &:focus {
    color: white;
    background: inherit;
    border-color: transparent;
  }
}

.button-component {
  width: 100%;
  border-radius: 5px !important;
  border-color: transparent !important;
  transition: 0.2s ease-in-out 0s;
  background: var(--btn-color) !important;

  &:hover {
    opacity: 0.98;
    cursor: pointer;
    transform: scale(1.02);
    background: inherit;
  }

  &:disabled {
    border: 1px solid #999999 !important;
    background: #cccccc !important;
    color: #666666 !important;
  }
}

.ant-table-thead .ant-table-cell {
  background: var(--btn-color) !important;
  color: var(--tb-color) !important;
}
