.menu-three-page {
    border: 6px solid black;
    border-radius: 2px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    background-origin: border-box;
    background-clip: border-box;
    padding: 33px;

    display: flex;
    justify-content: space-between;


    .left-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        color: #FDFDFD;
        text-shadow: 0px 0px 7.2px #FFFFFF40;
        letter-spacing: 0.07em;
        font-weight: 600;
        font-size: 16px;
    }

    .right-wrap {
        display: flex;
        flex-direction: column;

        div>div:nth-child(n + 2) {
            transform: scale(0.96);
        }

        div>div:last-child {
            margin-bottom: 0px;
        }
    }

    .weather-date {

        line-height: 43px;



        .time {
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0.05em;
            line-height: 40px;
        }

        .date {
            font-size: 18px;
        }

    }

    .icons-round-wrap {
        width: 240px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 20px;
    }

    .reload-app {
        background-color: #FAA72A;
        border: 1px solid #FAA72A;
        color: #fff;
        border-radius: 9px;
        width: 180px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        line-height: 0px;
        margin-top: 7px;

        svg {
            width: 18px;
        }

        span {
            font-weight: 600;
        }
    }

    .bg-icon {
        width: 53px;
        height: 53px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;

        svg {
            width: 43px;
            height: auto;
        }
    }

    .bg-live {
        background: #FAA72A;
    }

    .bg-movies {
        background: #2FBACD;
    }

    .bg-series {
        background: #6DBE70;
    }

    .bg-settings {
        background: #7B60A9;
    }

    .icons-wrap {
        margin-bottom: 20px;
    }

    .menu-icon {
        padding: 0px 22px;
        text-transform: uppercase;
        margin-bottom: 10px;
        width: 220px;
        height: 65px;
        display: flex;
        align-items: center;
        gap: 17px;
        line-height: 0px;
    }


    .menu-icon_round {
        padding: 12px 24px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: -0.03em;
        margin-bottom: 9px;
        width: 110px;
        height: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        line-height: 10px;

        svg {
            width: 45px;
            max-height: 35px;
        }

        .title {
            font-size: 12px;
        }
    }
}

@media (max-width: 1190px) {
    .menu-three-page {
        width: auto;
        max-width: 700px;
    }
}