@import "./colors.scss";
@import "./mixin.scss";

.header {
  background-color: #f6f6f6 !important;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 99;
  width: calc(100% - 280px);
  padding: 0 20px !important;

  .header-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header-sector-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partner-package {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ecedf251;
  padding: 15px;
  height: 45px;
  gap: 10px;
  border-radius: 10px;
  text-transform: uppercase;
  color: #073e4b;
  overflow: hidden;
  line-height: normal;

  svg {
    width: 30px;
  }
}

.profile-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;

  .profile-image-container {
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 73px;


  }

  .profile-text {
    display: flex;
    flex-direction: column;
    align-items: center;

    .name-surname {
      font-weight: 500 !important;
      color: #DEE4EE;
    }

    p {
      color: #DEE4EE;
      line-height: 20px;
      margin: 0;
    }

    .edit-profile {
      font-weight: 600;
      border-radius: 4px;
      height: 27px;
      padding: 2px 5px;
      display: flex;
      gap: 5px;
      transition: all 0.5s ease;

      svg {
        fill: inherit;
        width: 13px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.total-activations {
  height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  line-height: 1.2;
  color: #073e4b;
  gap: 10px;

  p {
    margin: 0;
  }
}

.activations-devices-profile {
  display: flex;
  align-items: center;
  gap: 20px;
}

.credit-sector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  color: $app;

  p {
    font-size: 13px;
    line-height: 1.2;

    margin: 0 !important;
  }
}

.buy-credit-btn {
  min-width: max-content;
  width: 170px;
  height: 40px !important;
  border-radius: 6px !important;
  background: #1e840d !important;
  border-color: transparent !important;
  color: #fff !important;
  transition: all 0.3s ease;

  span {
    font-weight: 500;
    font-size: 16px;
  }

  &:hover,
  &:focus {
    opacity: 0.6;
    background-color: #1e840d !important;
    border-color: transparent !important;
    color: #fff !important;
  }
}

.languages-select {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.app-login {
  .ant-form-item {
    .ant-row {
      display: flex;
      flex-flow: row-reverse !important;
      min-width: 0;
    }

    .ant-form-item-row {
      display: flex !important;
      flex-direction: row-reverse !important;
      align-items: baseline !important;

      .ant-form-item-control {
        width: 25px !important;
      }

      .ant-form-item-label {
        width: 100%;
      }
    }
  }
}

.partner-tariff-popover {
  cursor: pointer;
}

.tariff-content {
  p {
    display: flex;
    align-items: center;
    gap: 7px;
  }
}

.alerts-natification {
  position: absolute;
  z-index: 999999;
  max-width: 550px;
  top: 50px;
  border-radius: 7px;
  right: 0px;
  border: none;
  box-shadow: 1.95px 1.95px 2.6px rgba(0, 0, 0, 0.15);
}

.ant-alert-info {
  border: none !important;
}

.logout-button-classname {
  background: #bf4342 !important;
  border-color: #bf4342 !important;
  color: #fff !important;
}

.cancel-button-classname {
  width: 100px;
}

.payments-btns {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .body {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
}

.star {
  svg {
    width: 25px;
    height: 25px;
  }

  margin-right: 5px;
}

.more-header-icon {
  cursor: pointer;
  font-size: 17px;
  transition: 0.3s;
}

.view-more-wrap {
  left: 0;
  background: white;
  border: 2px solid #EFF0F6;
  width: 100%;
  height: 90px;
  position: absolute;
  top: 64px;
}

.mobile-device-profile {
  position: absolute;
  top: 83px;
  z-index: 10;
  left: 10px;
  display: flex;
  transition: 0.3s;
}

.mobile-languages {
  position: absolute;
  top: 86px;
  left: 80%;
  z-index: 10;
  display: flex;
  transition: 0.3s;
}

@media (max-width: 1024px) {
  .total-activations {
    p {
      font-size: 12px;
    }
  }
}

@media (max-width: 800px) {
  .header {
    width: 100%;
  }

  .header-sector-one {
    width: 100%;
  }

  .menu-content {
    width: max-content;
  }

  .credit-sector {
    flex-direction: column;

    p {
      font-size: 12px;
      line-height: 0.2;
    }
  }

  .buy-credit-btn {
    width: 130px;
  }

}

@media (max-width: 340px) {
  .total-activations {
    p {
      font-size: 13px;
    }
  }
}