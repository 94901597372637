@import "./colors.scss";
@import "./mixin.scss";

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 13px !important;
  right: -36px;
  left: 82%;
  z-index: 1;
  width: 56px;
  height: 42px;
  color: #fff;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  background: transparent !important;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: 0.2s;
}

.ant-layout-sider-zero-width-trigger:hover::after {
  background: transparent !important;
}

.ant-layout-sider-children {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.1px;
}

.length-menu-item {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
}

.badge {
  width: 20px;
  height: 20px;
  background: rgb(215, 81, 81);
  border: 1px solid transparent;
  border-radius: 50%;
  color: #fff;
}

.sidebar {
  ul::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #979797;
  }
}

.sidebar {
  ul::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: #979797;
  }
}

.sidebar {
  ul::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #979797;
  }
}

.sidebar {
  height: 100vh;
  width: 270px !important;
  max-width: 270px !important;
  overflow: hidden;

  ul {
    margin-bottom: 20px;
    background-color: inherit !important;

    li {
      // .sidebar-icon {
      //   width: 28px;
      //   height: 28px;

      //   svg {
      //     width: 28px;
      //     height: 28px;
      //   }
      // }

      .log-out {
        color: rgb(215, 81, 81) !important;
        font-size: 14px;

        svg {
          fill: rgb(215, 81, 81) !important;
        }
      }
    }
  }

  .ant-layout-sider-trigger {
    background-color: $app_light;
  }

  .logo-block {
    padding-top: 15px;
    margin-bottom: 20px;
  }

  .close-icon {
    position: absolute;
    right: 20px;
    top: 10px;
    color: white;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .logo {
    background-image: url("../images//logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 70px;
    transition: 0.2s;
    cursor: pointer;
  }

  .sidebar-footer {
    width: 100%;
    padding: 4px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
  }
}

.df-mneu-items {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-item-active {
  position: absolute;
  top: 0;
  right: 0;
}

.active-round {
  position: absolute;
  top: -17px;
  right: -46px;
  width: 37px;
  height: 32px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px;
  z-index: 22;
  transform: rotate(45deg);
}

.ant-menu-title-content {
  overflow: visible !important;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;

  img {
    width: 32px;
  }

  svg {
    width: 27px;
    height: 25px;
  }
}

.join-community {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #3083fb;
  border-radius: 50%;
  padding: 7px;
  width: 33px;
  height: 33px;
  cursor: pointer;
  animation-timing-function: linear;
  animation-name: animate-join;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 1s;
  transition: 0.2s;

  &:hover {
    transform: scale(1.1);
    background-color: #262626;
  }
}

.social-anim {
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  text-transform: uppercase;
  cursor: pointer;
  animation-timing-function: linear;
  animation-name: animate-pulse;
  animation-iteration-count: infinite;
}

.telegram {
  animation-duration: 3s;
  animation-delay: 10s;
}

.message-social-anim {
  animation-duration: 3s;
  animation-delay: 20s;
}

.sidebar-menu-item {
  padding: 0 24px !important;

  .sidebar-item-content {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-right: 15px;
  }

  .sidebar-icon {
    font-size: 20px;
  }

  .menu-item-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-tooltip-inner {
    max-width: none;
  }
}

.accounts-sidebar {
  display: flex;
  width: 100%;
  height: 100vh;
}

.menu-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebar-wrap {
  width: 100%;
  height: calc(100vh - 250px);
  overflow: hidden auto;

  .ant-menu-submenu-arrow {
    right: 40px !important;
  }
}

//multi accounts

.multi-accounts-page {
  box-shadow: 3px 3px 5px -4px inherit;
  padding: 15px 10px 10px 10px;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 20px;
  }
}

.multi-accounts {
  .account {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: scale(1.02);
    }

    img {
      padding: 7px;
      object-fit: cover;
      max-width: 100%;
    }
  }

  button {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    color: #fff;
    background: #979797;
    border-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: 0.2s;

    &:hover {
      color: #fff;
      background: #979797;
      border-color: transparent;
      opacity: 0.7;
      transform: scale(1.02);
    }

    &:focus {
      color: #fff;
      background: #979797;
      border-color: transparent;
      opacity: 0.7;
    }

    &:active {
      color: #fff;
      background: #979797;
      border-color: transparent;
      opacity: 0.7;
    }
  }
}

.account-select {
  color: #8c8c8c;
  font-size: 15px;
  font-weight: 400;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: #25f662;
  }
}

.account-del {
  color: #8c8c8c;
  font-size: 15px;
  font-weight: 400;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: #ff4d4f;
  }
}

.steps {
  margin: 40px;
}

.account-text-title {
  min-height: 150px;
}

.account-form-wrap {
  min-height: 125px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.attach-modal-container {
  max-width: 350px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  p {
    margin: 0 auto;
    font-weight: 400;
    color: #222222;
    opacity: 0.5;
    text-align: center;
  }
}

.accounts-confirmation-btns {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 15px;
}

.previous {
  background-color: transparent !important;
  border-color: #173e4b !important;
  color: #173e4b !important;
  height: 43px !important;
  width: 250px;
  font-size: 16px !important;
  border-radius: 10px !important;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.01);
    border-color: #173e4b !important;
    color: #173e4b !important;
    font-size: 16px !important;
  }
}

.account-modal {
  .ant-modal-content {
    border-radius: 27px;
    height: 550px;
    max-width: 500px;
    flex-shrink: 0;
  }
}

.account-popover {
  left: 58px;

  .ant-popover-arrow-content {
    --antd-arrow-background-color: #282828 !important;
  }

  .ant-popover-inner {
    background-color: #282828;
    border-radius: 4px;
  }
}

.account-form-wrap {
  .vi__container {
    .vi__character {
      border-radius: 6px !important;
      font-size: 22px;
      text-align: center;
      border: 1px solid #d0d1d0 !important;
    }
  }
}

:where(.vi__container) {
  width: auto !important;
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(60, 121, 143, 0.3098039216), 0 0 0 0 #ffffff;
  }

  40% {
    box-shadow: 0 0 0 10px rgba(255, 109, 74, 0), 0 0 0 0 #fff;
  }

  80% {
    box-shadow: 0 0 0 10px rgba(255, 109, 74, 0),
      0 0 0 10px rgba(255, 109, 74, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 10px rgba(255, 109, 74, 0);
  }
}

@keyframes animate-join {
  0% {
    box-shadow: 0 0 0 0 #3083fb, 0 0 0 0 #a8c8f4;
  }

  40% {
    box-shadow: 0 0 0 2px rgba(255, 109, 74, 0), 0 0 0 0 #fff;
  }

  80% {
    box-shadow: 0 0 0 3px rgba(255, 109, 74, 0), 0 0 0 2px rgba(255, 109, 74, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 3px rgba(255, 109, 74, 0);
  }
}

@media (max-width: 992px) {
  .sidebar {
    z-index: 1000;
  }

  .ant-menu-item {
    margin-left: 0px !important;
  }
}

@media (max-width: 800px) {
  .sidebar {
    z-index: 1000;
    width: 300px !important;
    max-width: 300px !important;
  }
}