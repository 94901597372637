.platforms-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 10px;
}

.platforms-list {
    display: flex;
    flex-wrap: wrap;
}

.platform-card {
    background-color: #183741;
    border-radius: 12px;
    padding-bottom: 10px;
    margin: 5px;

    .platform-wrap {
        border-radius: 12px;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .platform-img {
            width: 95px;

            img {
                max-width: 100%;
                height: 30px;
                object-fit: contain;
            }
        }

        .platform-body {
            min-height: 40px;

            .platform-name {
                color: #8b9ca1;
            }

            .count {
                color: #fff;
            }

        }

    }
}


.platform-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    width: 120px;

    .apk {
        color: #1890FF;
        font-weight: 700;
        cursor: pointer;
        transition: 0.3s;
        font-size: 11px;

        svg {

            fill: #1890FF;
            stroke: #258ffb;
            width: 7px;
            height: 7px;
        }

        &:hover {
            color: #407bff;

            svg {
                fill: #407bff;
                stroke: #407bff;
            }
        }
    }

    .available {
        background-color: #D4F8D3;
        color: #183741;
        padding: 4px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        font-size: 10px;
    }

    .soon {
        background-color: #7ba4ab47;
        color: #fff;
        padding: 4px;
        border-radius: 8px;
        text-align: center;
        font-weight: 500;
        font-size: 11px;
    }

}

.platform-disabled {
    background: radial-gradient(circle, #5c7b82 0%, rgb(24, 55, 65) 100%) !important;
}

.title {
    color: #183741;
    font-weight: 500;
    font-size: 28px;
}

.sub-title {
    color: #183741;
    font-weight: 500;
    font-size: 23px;
}

@media (max-width: 490px) {
    .platforms-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .platforms-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    }
}

@media (max-width: 320px) {
    .platforms-list {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}