// a
$app: #173E4B;
$app_light: #2d738a;
$app_dark: #000;
// b
// c
// d
// e
// f
// g
// h
// i
// j
// k
// l
// m
// n
// o
// p
$package_name: #587b73;
// q
// r
// s
$success: #1890ff;

// t
$title: #111826;

// u
// v
// w

// x
// y
// z