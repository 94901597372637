.menu-page {
    width: 100%;
    display: flex;
    // gap: 0 50px;
}

.choose-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.choose-list {
    .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.collection-img {
    img {
        border-radius: 50%;
        background: #939393;
    }
}


.reload-app {
    background: rgba(255, 255, 255, 0.1019607843);
    box-shadow: 0px 6.99px 6.99px 0px rgba(255, 255, 255, 0.2509803922) inset;
    border: 1.3px solid rgb(112, 112, 113);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 33px;
    gap: 10px;
    border-radius: 5px;
}


@media (max-width: 1190px) {
    .menu-page {
        flex-direction: column;
    }

    .choose-wrap {
        margin-bottom: 20px;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 17px 20px;
    }

    .choose-list {
        .ant-radio-group {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}