.devices-page {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 10px;
}

.sync-playlist {
    display: flex;
    justify-content: center;
    cursor: pointer;

    svg {
        fill: #1890ff;
    }
}

.on-info {
    transition: 0.3s;
    cursor: pointer;
    color: #073e4b;

    &:hover {
        transform: scale(1.02);
    }
}

.no-sync-playlist {
    display: flex;
    justify-content: center;
    cursor: not-allowed;

    svg {
        fill: lightgrey;
    }
}

.search-device-info {
    height: 60px;
}

.list-head-page {
    display: flex;
    list-style: none;
    gap: 10px;

    li {
        border-top: 1px solid #d6e1e0;
        padding-top: 10px;
    }
}

.tb-head-global-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    gap: 10px;
}

.devices-head {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #edeff2;
    margin-bottom: 20px;
}

.reset-cancel-app {
    display: flex;
    gap: 10px;

    button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        gap: 5px;
        padding: 0px 10px;
        border-radius: 2px;
        transition: 0.3s;

        &:focus {
            outline: none;
        }

        &:first-child {
            background-color: #eb595a;
            color: #fef;

            svg {
                fill: #fef;
            }

            &:hover {
                color: #fff;
                transform: scale(1.03);
                background-color: #eb595a;
            }
        }

        &:last-child {
            background-color: #7c7979;
            color: #fef;

            svg {
                fill: #fef;
            }

            &:hover {
                color: #fef;
                transform: scale(1.03);

                background-color: #7c7979;
            }

        }
    }

}

.head-statistics {
    .ant-radio-button-wrapper {
        border: 1px solid transparent !important;
        border-top-width: 0px !important;
        border-left-width: 1px !important;
    }

    .ant-radio-group-solid,
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: #1890ff !important;
        background: #fff !important;
        border-color: transparent !important;
    }
}

.descriptions-item {
    font-size: 16px;
    color: rgb(242, 91, 91);
    cursor: pointer;
    text-decoration: underline;
    margin-right: 15px;
}

.card-extra {
    display: flex;
    gap: 7px;
}

.about-device {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 30px;

    .part-1 {
        min-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-width: 57%;

    }

    .part-2 {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-width: 57%;
    }
}

.card-info {
    li {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        padding: 10px 10px 5px 10px;
        transition: "background" 0.3s ease;

        &:hover {
            background: #f3f3f3;
        }

        &:not(:last-child) {
            border-bottom: 1px solid lightgray;
        }

        span:first-child {
            color: #7c7979;
        }
    }
}

.unblock-device {
    button {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;
        gap: 5px;
        padding: 0px 10px;
        border-radius: 2px;
        transition: 0.3s;
        background: transparent !important;
        color: #000 !important;

        &:focus {
            outline: none;
        }

        &:first-child {
            background: transparent !important;
            color: #000 !important;

            svg {
                fill: #000;

            }

            &:hover {
                background: transparent !important;
                color: #258ffb !important;

                svg {
                    fill: #258ffb !important;
                }
            }
        }

    }


}

.checkbox-row {
    .ant-form-item {
        .ant-row {
            display: flex;
            flex-flow: row-reverse !important;
            min-width: 0;
        }

        .ant-form-item-row {
            display: flex !important;
            flex-direction: row-reverse !important;
            align-items: baseline !important;

            .ant-form-item-label {
                width: 470px;
                margin-left: -85% !important;
            }
        }
    }
}

.green-row {
    background-color: #f6fded7a;
}

.gold-row {
    background-color: #fffce642;
}

.red-row {
    background-color: #fcf1f091;
}

.tow-actions-btn {
    display: flex;
    gap: 8px;
}

.devices-header {
    margin-bottom: 10px;

    .view-more-info {
        padding: 0px;
    }
}

.devices-total-info {
    p {
        padding: 10px;
        line-height: 1;
        background-color: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        transition: "background-color" 0.3s ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        span {
            font-weight: 600;
            text-transform: capitalize;
            letter-spacing: 0.5px;
        }
    }
}

@media (max-width: 1100px) {
    .about-device {
        grid-template-columns: 1fr;

        .part-1 {
            min-width: 100%
        }

        .part-2 {
            min-width: 100%;
        }
    }
}