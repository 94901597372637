.menu-page-two {
    border: 6px solid black;
    border-radius: 2px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image: url(../../../images/brending-menu-page/menu-bg.jpg);

    background-origin: border-box;
    background-clip: border-box;
    padding: 15px;

}

.menu-page-wrap {
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}



// rms version 1 menu
.rms-menu-items {
    width: 250px;
    height: 217px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 7px;
    gap: 8px 20px;
}

.rms-items-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rms-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 81px;
    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .light-effect {
        width: 153px;
        position: absolute;
        transform: translate(-2px, 1px);
        z-index: 2;
    }

    svg {
        width: 53px;
        height: 53px;
        padding: 5px;
        position: absolute;
        z-index: 2;
    }
}

.rms-item-title {
    line-height: 0;
    letter-spacing: 0.02em;
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    text-transform: uppercase,
}

.rms-wrap-reload {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    div {
        width: 160px;
        margin-left: -44px;
    }
}

// Buttons

.menu-buttons {
    width: 290px;
    height: 213px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 14px;

    .menu-buttons-list {
        width: 117px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        position: relative;

        img {
            width: 78px;
            object-fit: cover;
        }

        p {
            line-height: 0;
            color: #f5f5f5;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .menu-btn-collection-list {
        width: 117px;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
        position: relative;
        margin-bottom: 14px;

        .box-round {
            width: 75px;
            height: 75px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 4px;

            svg {
                width: 47px;
                height: 47px;
                padding: 5px;
            }

            p {
                position: absolute;
                top: 80px;
            }
        }

        .wrap {
            width: 95px;
            height: 95px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 7px;

            svg {
                width: inherit;
            }

            p {
                line-height: 0;
                color: #f5f5f5;
                font-size: 14px;
                font-weight: 500;
                margin-top: 14px;
            }
        }
    }
}



.active-btn {
    width: 78px;
    border-radius: 50%;
    top: 14px;
}

.menu-page-two-tv-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #fff;
}

.left-section {
    width: 757px;

    .logo {
        height: 70px;
        width: max-content;
    }
}

.right-section {
    width: 100%;
}

.media {
    margin-bottom: 14px;

    h2 {
        color: #f5f5f5;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.7;
    }

    .media-lists {
        width: 100%;
        display: flex;
        gap: 8px;

        img {
            width: 95px;
            object-fit: cover;
            border-radius: 4px;
        }
    }
}

@media (max-width: 1190px) {
    .menu-page-two {
        width: 100% !important;
    }

    .left-section {
        width: 270px !important;
    }


    .media {
        .media-lists {
            img {
                width: 23%;
                height: 140px;
                object-fit: cover;
            }

        }
    }


}