.result-modal-component {
  .ant-modal-content {
    border-radius: 15px !important;
  }
}

.result-modal-component-wrap {
  background-color: rgba(0, 0, 0, 0.47);
}

.result-modal-header {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px !important;

  svg {
    width: auto;
    height: 120px;
  }
}

.result-modal-body {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.result-modal-ok-button {
  width: 70px;
}
