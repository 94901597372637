@import "./colors.scss";
@import "./mixin.scss";

.authorization-page {
  width: 100%;
  height: 100vh;
  display: flex;

  .left {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-block {
      width: 100%;
      height: 87%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .footer-login {
    height: 13%;

    span {
      a {
        color: #fff;
        transition: 0.3s;

        &:hover {
          opacity: 0.8;
          text-decoration: underline;

        }
      }
    }

    p {
      margin: 0;

      a {
        color: #fff;
        transition: 0.3s;

        &:hover {
          opacity: 0.8;
          text-decoration: underline;
        }
      }
    }

    color: #fff;
    text-align: center;
  }

  .right {
    width: 50%;
    height: 100vh;
    @include flexible("", center, center);

    .container {
      width: 370px;

      .logo-right {
        display: none;
      }

      h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
      }

      .subtitle {
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        span {
          color: #636161;
        }
      }
    }
  }
}

.login-container-footer {
  display: none;
}

@media screen and (max-width: 992px) {
  .subtitle {
    span {
      color: #dfcccc !important;
    }
  }

  .signup,
  .forgot-your-password {
    color: #67baff;
  }

  .dont-have-account {
    color: #dfcccc;
  }

  .authorization-page {
    width: 100%;
    justify-content: center;

    .left {
      display: none;
    }

    .right {
      width: 100%;
      flex-direction: column;
      justify-content: space-around;

      .container {
        max-width: 340px;

        padding: 25px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.1);

        .logo-right {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100px;
        }

        h1 {
          color: #fff !important;
        }
      }
    }
  }

  .login-container-footer {
    display: block;
  }

  .footer-login {
    width: 100%;
    color: #fff;
    text-align: center;
    min-width: 300px;
    font-size: 12px;
    padding-bottom: 5px;

    p {
      margin: 0;
    }
  }
}