@import "../styles/mixin.scss";
@import "../styles/colors.scss";

.dashboard-content {
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

.guide-sector {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: calc(100% - 280px);
  height: 180px;
  padding: 20px;
  position: absolute;
  left: 270px;
  top: 66px;
  border-radius: 0px 0px 7px 7px;
  color: #fff;
  z-index: 1;

  h1 {
    font-size: 28px;
  }

  h1,
  h3,
  svg {
    color: #fff;
    fill: #fff;
  }

  .guide-body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  }

  p {
    margin-bottom: 5px !important;
  }

  svg {
    transform: rotate(270deg);
  }
}

.wrapper-box {
  background-color: #fff;
  box-shadow: 0px 0px 2px 1px #f9f8fa;
  color: #000;
  border-radius: 12px;
  padding: 15px;
  height: 100%;
  width: 100%;
}

.coming-soon-platform,
.have-platform {
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
}

.devices-chart-container,
.platform-statistic-chart-container {
  height: 400px !important;
  box-shadow: rgba(0, 0, 0, 0.095) 0px 1px 4px;
  background-color: #fff;
  padding: 30px;
  border-radius: 16px;
  text-align: start;
  @include flexible(column, "", "");

  .chart-block {
    width: 450px;
    margin: auto;
  }
}

.geo-chart {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 16px;
  padding: 10px;
  height: 400px !important;
}

.geo-devices {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: minmax(200px, 3.5fr) minmax(100px, 1fr);
  height: 400;
}

.charts {
  width: 100%;
  height: 100%;
  margin-top: 40px;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.registered-devices-chart,
.lives-statistics-chart,
.movies-statistics-chart,
.series-statistics-chart {
  padding: 20px;
  background-color: rgb(207 227 231 / 30%);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 4%) 1px 0px 9px;
  height: 450px;

  h3 {
    color: #183741 !important;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 25px;
  }
}

.movies-lives-series-startistics-charts {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 1rem;
  margin-top: 16px;

  .datepicker {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .view-more-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #183741;
    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}

.online-devices-chart {
  padding: 20px;
  background-color: rgb(207 227 231 / 30%);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 4%) 1px 0px 9px;
  height: 450px;

  .devices-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    height: 310px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(90, 90, 90);
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cfe3e7;
      border-radius: 10px;
    }
  }
}

.table-name-img-column {
  display: flex;
}

.empty-data {
  padding-top: 50px;
}

.go-back {
  width: max-content;
  cursor: pointer;
  line-height: 0;
  margin-bottom: 20px;

  h3 {
    transition: all 0.5s ease;

    &:hover {
      color: dodgerblue;
    }
  }
}

.hide-pannel .ant-picker-panel-container .ant-picker-panel .ant-picker-month-panel .ant-picker-header {
  display: none !important;
}

.geo-section {
  position: relative;
  min-height: 400px;
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.country-card {
  background: #fff;
  width: 40%;
  height: max-content;
  overflow: auto;
  padding: 0 16px;
}

.dashboard-new-device {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid transparent !important;
  color: #fff !important;
  border-radius: 7px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 20px 10px !important;

  svg {
    fill: #fff !important;
    width: 42px;
    height: 42px;
    transform: scale(0.7);
  }

  &:hover {
    border: 1.3px solid inherit !important;

  }
}

@media (max-width: 1300px) {
  .geo-section {
    flex-wrap: wrap;
  }
}

@media (max-width: 1124px) {
  .dashboard-content {
    .wrapper {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 820px) {
  .geo-section {
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .guide-sector {
    width: 100%;
    left: 0px;
  }
}

@media (max-width: 768px) {
  .movies-lives-series-startistics-charts {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .registered-devices-chart__top,
  .lives-statistics-chart__top,
  .movies-statistics-chart__top,
  .series-statistics-chart__top {
    flex-direction: column;
  }

  .country-card {
    width: 100%;
  }

  .dashboard-content {
    .wrapper {
      justify-content: center;
    }
  }
}

@media (max-width: 570px) {

  .coming-soon-platform,
  .have-platform {
    justify-content: center;
  }

  .guide-sector {
    h1 {
      font-size: 18px;
    }
  }
}