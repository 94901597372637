@import "./colors.scss";
@import "./mixin.scss";


.input-component {
  border-radius: 5px !important;
}

.message {
  @include flexible("", flex-start, center);
  text-align: center;
  height: 0;
  transition: height 0.2s ease-in-out;
}

.message.open {
  overflow: hidden;
  height: 50px;
}

.tab-bar {
  width: 100%;
  padding: 10px;
  @include flexible("", center, center);
  gap: 10px;
  outline: none !important;

  .item {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    @include flexible("", center, center);
    color: #073e4a;
    font-size: 20px;
    background: transparent;
    border: 1px solid #073e4a;
    cursor: pointer;

    transition: 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.391);
    }
  }

  .dont-hover {
    background: none !important;
    cursor: no-drop;
  }

  .active {
    background: #073e4a !important;
    color: #fff;
  }
}

.translations-table,
.cursor-pointer {
  cursor: pointer;
}

.translation-drawer-key {
  margin-bottom: 10px;
  color: #b0b0b0;
}

.under-construction_mp {
  align-items: center;
  background: #fefbf3;
  border: 1px solid #fad492;
  color: #d67230;
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
  width: 450px;
  text-align: center;
  padding: 20px;
  border-radius: 1px;
}

@media screen and (max-width: 992px) {
  .under-construction_mp {
    width: 100% !important;
  }
}