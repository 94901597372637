  .tv-movies-container {
      overflow: hidden;
      padding: 15px;
      display: flex;
      gap: 10px;

      .tv-movies-categories_items {
          display: flex;
          flex-direction: column;
          gap: 5px;
      }

      .cat-name {
          color: #fff;
          font-weight: 600;
          padding: 10px;
          font-size: 12px;
      }

      .lock {
          color: #fff;

          svg {
              width: 24px !important;
              padding-right: 5px;
          }
      }

      .tv-movies-container_items {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          height: 365px;
          width: 100%;

          .tv-movies-container_items_item {
              position: relative;
              background: rgba(0, 0, 0, 0.85);

              img {
                  width: 100%;
                  height: -webkit-fill-available;
                  border-radius: 4px;
              }

              .star {
                  position: absolute;
                  top: 0;
                  left: 4px;

                  svg {
                      width: 12px;
                  }
              }

              .move-lock {
                  position: absolute;
                  top: 0;
                  right: 4px;
                  color: #fff;

                  svg {
                      width: 20px;
                  }
              }

              .move-favorit {
                  position: absolute;
                  top: 0;
                  right: 4px;

                  svg {
                      width: 12px;
                  }
              }

              .block {
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  padding-left: 1px;
                  font-weight: bold;
                  font-size: 13px;
                  width: 100%;
                  color: #fff;
                  background: linear-gradient(45deg, black, transparent);
                  z-index: 5;
                  -webkit-text-stroke-width: 0.2px;
                  -webkit-text-stroke-color: #000000;
              }
          }
      }
  }