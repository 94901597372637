.live-tv-container {
    display: flex;
    flex-direction: column;
    border: 6px solid black;
    border-radius: 2px;

    .live-tv-container-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 33px;
        margin: 7px 0;

        .live-tv-container-header_item {
            border-radius: 3px;
            color: #fff;
            font-weight: 600;
            padding: 5px;
            width: 316px;
        }

        .live-tv-container-header_item.block2 {
            display: flex;
            justify-content: space-between;
            width: 415px;
            color: #fff;
        }
    }

    .live-tv-container-main-content {
        display: flex;
        justify-content: space-between;
        color: #fff;
        gap: 10px;

        .left-sector {
            display: flex;
            gap: 10px;
            width: 330px;
        }

        .live-tv-container-main-content-item {
            .single-film {
                background: rebeccapurple;
                display: flex;
                align-items: center;
                border-radius: 6px;
                font-weight: 600;
                font-size: 13px;
                margin-bottom: 4px;
                width: 260px;
                height: 42px;
            }
        }

        .film-name {
            font-size: 13px;
        }

    }





    .live-tv-container-main-content-item2 {
        width: 415px;

        .live-tv-container-main-content-item2_tv {
            background-image: url("../../../images/brending_page/img/channels//CNN\ live\ TV\ news\ Poster.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 247px;
            margin-bottom: 4px;
        }

        .live-tv-container-main-content-item2_tv-live {
            display: flex;
            align-items: center;
            width: 100%;
            height: 70px;
            margin-bottom: 4px;
            border-radius: 3px;
            font-weight: 600;
            font-size: 10px;
        }

        .live-tv-container-main-content-item2_bottom {
            border-radius: 3px;
            padding: 0 48px;
            display: flex;
            justify-content: space-between;
            height: 38px;
            color: #fff;

            .live-tv-container-main-content-item2_bottom_single-item {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 11px;
                margin: 0;

                .live-tv-container-main-content-item2_bottom_single-item-color {
                    width: 9px;
                    height: 5px;
                    margin-right: 10px;
                }
            }
        }
    }
}