.vpn-sector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vpn-background{
  max-width: 600px;
  img{
    width: 100%;
    object-fit: cover;
  }
}
.vpn-wrap {
  max-width: 600px;
  text-align: center;

  button {
    margin-top: 10px;
    color: #121315;
    border-radius: 5px;
    border: 1px solid #121315;
    transition: 0.2s;
    width: 200px;
    border-radius: 20px !important;

    svg {
      fill: #121315;
    }

    &:hover {
      opacity: 0.8;
      transform: scale(1.05);

      svg {
        path {
          fill: #40a9ff;
        }
      }
    }

    &:focus {
      color: #121315;
      border: 1px solid #121315;
    }
  }
}

.como-instalar {
  color: #40a9ff;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    text-decoration: underline;
  }
}
