@import "../styles/mixin.scss";

.head-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.billing-history {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  gap: 10px;
}

.arrow {
  display: flex;
  padding: 0;
  justify-content: space-around;
  margin: 0;
}

@media (max-width: 992px) {
  .billing-history {
    flex-direction: column;
    margin-bottom: 20px;
  }
}