.packages-list {
  margin-top: 5px;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
}

.package-container {
  width: 190px;
  transition: 0.2s;

  &.selected-package {
    transform: scale(1.05);

    .package {
      background: #edf3ee;
      border-color: #69c930;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}

.trending-package {
  height: 20px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 7px;
  color: #fff;
  font-size: 10px;
  background: linear-gradient(90deg, #0f7000 -19.7%, #41c931 116.87%);
}

.package {
  background-color: #f9f9f9;
  padding: 15px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  border: 2px solid transparent;

  .background {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .package-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #80808080;
      padding: 1.4px;

      .selected-circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #69c930;
      }
    }
  }

  .package-title-block-wrap {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .package-title {
    color: #121315;
    font-size: 20px;
    font-weight: 500;
  }

  .sale {
    background: #69c930;
    color: #fff;
    padding: 2px 6px;
    border-radius: 6px;
    transform: scale(1.1);
  }

  .package-body {
    padding: 0px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .price_dollar {
    color: #3cc82c;
    font-size: 30px;
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 6px;
    width: max-content;

  }

  .old_price {
    font-size: 17px;
    color: #808080;
  }

  .price_br {
    font-size: 17px;
    color: #808080;
    font-weight: 500;
  }

  .package-credit {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #808080;
    font-size: 16px;
    font-weight: 500;

    .package-icon {
      svg {
        width: 30px;
        height: 30px;
        margin-top: 4px;

        path {
          fill: #3cc82c;
        }
      }
    }
  }

  .package_credit_body {
    margin-top: 8px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .package-container {
    &.selected-package {
      transform: scale(1);
    }

    &:hover {
      transform: scale(1);
    }
  }
}